@media only screen {
    #aaa_studioContainer{
        position: relative;
        top: 0; left: 0;
        width: 100%;
        /* padding: 5px 20px; */
    }
    #aaa_studio0{
        position: relative;
        top: 0; right: 0;
        width: 100%;
        font: 40px Cumulus, Verdana, Arial;
        text-align: right;
    }
    #aaa_studio1, #aaa_studio3{ 
        margin: 0 0 5px 0; 
        font: 16px/36px Ubuntu, sans-serif, Arial, Helvetica;
    }
    #aaa_studio2{ background-image: url('../../img/misc/aaa_studio1.jpg'); }
    #aaa_studio4{ margin: 5px 0; background-image: url('../../img/misc/aaa_studio2.jpg'); cursor: pointer; }
  

    /* NEW 23/Oct/2018 */
    .aaa_tdVTop{ vertical-align: top }
    .aaa_rowStudio{
        position: relative; width: 100%; height: auto;
    }
    #aaa_picStudio1{
        position: relative; float: right; width: 40%; height: auto; margin: 0 0 5px 10px;
    }
    #aaa_picStudio2{
        position: relative; float: left; width: 40%; height: auto; margin: 10px 10px 10px 0;
    }
    
    #aaa_ceoProfile{
        position: absolute; top: 0; left: 0; width: 36%; height: 100%; padding: 15px 15px 15px 0; font: 13px/20px Helvetica, Verdana, Arial; color:#444; overflow: hidden; text-align: justify;
    }
    #aaa_ceoProfilex {
        position: absolute; top: 40%; left: 0; width: 37%; height: 100%; padding: 15px ; font: 13px/20px Helvetica, Verdana, Arial; color:#444; overflow: hidden; text-align: justify; background: rgba(255,255,255,0.8);
    }
        #aaa_ceoProfile span, #aaa_ceoProfilex span{font: bold 16px Ubuntu, Helvetica, Verdana, Arial}


    #aaa_ceoProfile3{ 
        position: absolute; top: 0; left:0; width: 27.5%; height: 100%; font: 14px/21px Ubuntu, Helvetica, Verdana, Arial;        
        color: #444; overflow: hidden; background: rgba(255, 255, 255, 1); text-align: justify }

        #aaa_ceoProfile3_1{ position: absolute; width: 100%; bottom: 0; padding: 15px 15px 0 0; }
            #aaa_ceoProfile3_1 span:first-of-type{ font: bold 18px Helvetica, Verdana, Arial }
            #aaa_ceoProfile3_1 span:nth-of-type(2){ font: bold 16px Helvetica, Verdana, Arial }
        #aaa_ceoProfile3_2{ border-left: 1px solid #444; padding: 3px 10px; font: 24px/28px  Ubuntu, Helvetica, Verdana, Arial; }            

    #aaa_ceoProfile4{
        position: relative; top: 0; left: 0; width: 100%; font: 14px/24px  Ubuntu, Helvetica, Verdana, Arial; color:#444 !important; overflow: hidden; text-align: justify;
    }
        #aaa_ceoProfile4_1{ position: relative; width: 100%; }
            #aaa_ceoProfile4_1 span:first-of-type{ font: bold 18px Helvetica, Verdana, Arial }
            #aaa_ceoProfile4_1 span:nth-of-type(2){ font: bold 16px Helvetica, Verdana, Arial }
        #aaa_ceoProfile4_2{ border-left: 1px solid #444; padding: 3px 10px; font: 24px/32px  Ubuntu, Helvetica, Verdana, Arial; }

    #aaa_ceoProfile, #aaa_ceoProfile3, #aaa_ceoProfilex{ opacity: 0;  }
    #aaa_ceoProfile2, #aaa_ceoProfile4, #aaa_ceoProfilex2{ height: auto; opacity: 1;}
    
    #aaa_ceoProfile2, #aaa_ceoProfilex2{
        position: relative; top: 0; left: 0; width: 100%; font: 14px/28px  Ubuntu, Helvetica, Verdana, Arial; color:#444 !important; overflow: hidden; text-align: justify;
    }
        #aaa_ceoProfile2 span, #aaa_ceoProfilex2 span{ font: bold 16px Ubuntu, Helvetica, Verdana, Arial }

    #downloadportfolio{
        position: relative; width: 100%; top: 0; left: 0; text-align: right;
        font: 11px Ubuntu, Arial, Helvetica; color: #777; cursor: pointer;
    }

    @media (min-width: 1530px){
        #aaa_ceoProfile, #aaa_ceoProfile3, #aaa_ceoProfilex{ opacity: 1; }
        #aaa_ceoProfile2, #aaa_ceoProfile4, #aaa_ceoProfilex2{ height: 0; opacity: 0; }
    }



    .rowContainer{
        position: relative;
        height: auto;
    }

}


.mapezz{
    position: relative;
    top: -20px;
    width: 100%;
}

@media (min-width: 1530px){
    .mapezz{ 
        top: -20px;
        width: 280px;
        height: 120%;
        padding-right: 50px;        
    }
}



.mapezzgrad{
background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 63%, rgba(255,255,255,0) 100%); 
background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 63%,rgba(255,255,255,0) 100%);
background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 63%,rgba(255,255,255,0) 100%); 
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); 

}