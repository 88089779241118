.browser {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 300px;
    background: rgba(255, 255, 255, 0.7);
    z-index: 20;
    border: 2px solid white;
    border-radius: 30px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    font: 14px/20px Arial, Helvetica, Sans-serif;
    transform-origin: 50% 50%;
    animation: browsers 800ms ease-out; }
    .browser img {
      margin: auto;
      width: 50%;
      height: auto; }
    .browser span {
      font-weight: bold; }
  
  .close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 18px;
    height: 18px;
    background: red;
    border-radius: 50%;
    border: 4px solid black;
    cursor: pointer; }
  
  @keyframes browsers {
    from {
      opacity: 0; }
    to {
      opacity: 1; } }
  