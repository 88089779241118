
@media only screen {


    #aaa_careersContainer{
        position: relative;
        margin-top: 100px
    }


    #aaa_careersForm{
      position: relative;
      top: 50%; 
      width: 96%; 
      min-width: 310px; 
      margin: auto;
      overflow: hidden;
      border: 30px solid rgba(255, 255, 255, 0.897);
      border-radius: 10px;
      text-align: left !important;
      background: white !important;
      -webkit-transform: translateY(-50%);
      -mz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    form > h4 { position: relative; width: 90%; margin: auto; text-align: center; }
    form > ul { position: relative; margin: auto; list-style-type: none; padding: 0; }
    form > ul li { width: 96.5%; margin: auto; }
  
    #aaa_careersL1{ font: 15px Arial, Helvetica, Sans-serif; }
  
    #aaa_cf1, #aaa_cf3 {
      width: 70%;
    }
  
    #aaa_cf2, #aaa_cf4 {
      width: 30%;
    }
  
    input[id^="aaa_cf"] {
      height: 24px;
      border: 1px solid #888;
      padding-left: 5px;
    }
  
    #aaa_careers4 option {
      line-height: 24px;
    }
  
    input[type="submit"], input[type="reset"] {
      height: 50px;
      border-color: transparent;
      border-radius: 5px;
      font: 14px/20px Ubuntu, Arial, Verdana;
      color: #fff;
    }
  
    input[type="submit"]:hover, input[type="reset"]:hover {
      cursor: pointer;
      font-size: 1.9vh;
      text-shadow: 0 1px 2px #000;
    }
  
    input[type="submit"] {
      width: 78%;
      background: rgb(140, 180, 206);
      float: right;
    }
    
    input[type="reset"] {
      left: 0;
      width: 20%;
      background:  rgb(56, 0, 0);
    }
    #aaa_cf5{background: rgb(25, 25, 34) center no-repeat url('../../img/misc/refresh-icon.png'); }
  
    ::-webkit-input-placeholder,
    :-moz-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder {
      color: #6d6d6d;
    }
  
  
    
      @media (orientation: portrait) {
          @media (min-width: 550px){
        #aaa_careersForm{ width: 80%; max-width: 800px;}
          }
          @media (min-width: 800px){
            
          }
          @media (min-width: 1024px){
           
          }
      
      }
      @media (orientation: landscape) {
          @media (min-width: 550px){
        #aaa_careersForm{ width: 80%; max-width: 800px;}
          }
  
      }
  
  
  
  }
  
  
  /* NEW CAREERS */
  @media only screen {
    #aaa_careers0T{
        font: bold 40px Cumulus, Verdana, Arial;
    }
    #aaa_careers1{
        font: 16px/28px UbuntuLight, Arial, Helvetica, Sans-serif;
        text-align: justify;
    }
    #aaa_careersSendDoc{
        position: relative;
        width: 63px; height: 60px; margin: auto;
        background-image: url('../../img/misc/sendDoc.png');
        cursor: pointer; 
    }
    #aaa_careers2T, #aaa_careers3T{
        font: bold 18px/28px Arial, Helvetica, Sans-serif;
    }
    #aaa_careers3Text{
        line-height: 28px;;
        font-size: 13px;
        text-align: justify;
    }
    #aaa_careers3Text strong{ font-size: 15px; font-weight: bold !important; }

    #aaa_careers3Text2{
        position: relative;
        width: 80%; margin: auto;
    }
  
    .aaaJobTables{
        width: 100%;
        border-top: 1px solid #bbb;
        font: 15px/22px UbuntuLight, sans-serif, Arial, Helvetica;
    }
    .aaa_careersJobTitle{ font-size: 20px;  }
    .aaaJobTable table TD{ min-height: 55px; }
    .aaa_jobCode_RequirementsTD{ width: 100px; font-weight: bold; }
  
    #aaa_jobForm{
        position: absolute;
        top: 0; left: 0; width: 100%; height: 100%;
        background: rgba(255,255,255,0.5);
    }
  
    .aaa_aux1{ position: relative; top: 0; width: 50%; margin: 15px auto; text-align: center; line-height: 30px}
  
    input[id="aaa_uploadFile"]{
        width: 296px;
        padding: 15px; 
        background:rgb(163, 163, 163); 
        border: 1px solid #fff;
        cursor:pointer;
        -webkit-border-radius: 5px;
        border-radius: 5px; 
        font: 20px Helvetica, Verdana, Arial;
        box-shadow: 0 1px 7px 1px #999;
        color: white;
    }
        input[id="aaa_uploadFile"]:hover{ box-shadow: none; }
  
  
    #bgJobs{
      position: absolute; top: 0; width: 100%; height: 100%; background-image: url('../../img/misc/bg-job.jpg');
    }
  
  
    /* NEW 23/Oct/2018 */
    #aaa_careers3 { font-family: UbuntuLight, sans-serif, Arial, Helvetica }
    .aaa_hr{ width: 100px; height: 1px; border:1px solid #ccc; }
    #aaa_careers3_0, #aaa_careers3_1{ position: relative; width: 100%; line-height: 30px; }
    #aaa_careers3_2{position: relative; width: 100%; height: 70px;}
  
    #orderedList2{ padding: 30px !important }
  
  
  
  .clickToSeeForm{
    position: relative; width: 100%; height: 30px; text-align: center
  }
  
  .permtemp{
    font: normal 14px Arial
  }
  
  
  
  
  
  }
  
  
  
  