/* Content */
@media only screen {

    #aaa_contactContainer{
        position: relative;
        margin-top: 100px;
    }

	#_aaaContainerScroller_Contact {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto !important;
		margin: 0;
		overflow: hidden;
		padding: 10px;
		/* background: white; */
		background: rgba(255,255,255,0.67);
		border: 1px solid #fff;
	}
	#contact {
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 0 15px;
	}
	#contact div {
		position: relative; width: 100%;
		border: 1px solid red;
	}

	.contacttitle {
		top: 25px;
		left: 0;
		height: 24px;
		font: 22px Ubuntu, Verdana, Arial !important;
		color: black;
		margin: 20px 0;
	}

	#officelocation, #envienospeticiones {
		left: 0;
		font: 16px Ubuntu, Verdana, Arial !important;
	}
	#officelocation {
		/* margin-top: 15%; */
		margin-top: 25px; 
	}

	#envienospeticiones {
		/* margin-top: 7%; */
		margin-top: 25px;
	}

	.location {
		height: auto;
		border-top: 1px solid black;
		border-bottom: 1px solid black;
		color: #000;
		font: 13px/22px Ubuntu, Verdana, Arial, sans-serif !important;
		text-align: center;
		padding: 10px;
		margin-bottom: 10px;
	}
	.location div {
		position: relative;
		/* display: inline; */
		width: 14px;
		height: 13px;
		overflow: hidden;
		margin-right: 5px;
	}
	.location img {
		position: absolute;
		clip: rect(75px, 95px, 90px, 70px);
		top: -75px;
		left: -90px;
	}

	@media (orientation: portrait){
		@media (min-width: 481px){
			#_aaaContainerScroller_Contact {
				position: relative !important;;
				margin: auto;
				padding: 20px;
			}
		} 		
		@media (min-width: 600px){
			#_aaaContainerScroller_Contact {
			    width: 100% !important;
			}
		}  	
	}  /* END Portrait */

	@media (orientation: landscape){
		@media (min-width: 500px){
		  #_aaaContainerScroller_Contact {
		    padding: 20px; margin: auto;
		  }
		}	
	
	}  /* END landscape */

}

/* ++++ CONTACT FORM ++++ */		
@media screen {
	.aaa_form {
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
		overflow: hidden;
		text-align: center;
		font: 14px/24px Arial, Helvetica, Sans-serif;
	}

	.aaa_form a {
		padding: 20px;
		border: 1px solid rgb(49, 131, 209);
		background: white;
		border-radius: 20px;
		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		-ms-border-radius: 20px;
		-o-border-radius: 20px;
		margin-bottom: 10px;
		box-shadow: inset 0 0 7px black;
}

	#aa10_4 {
		height: 100%;
		width: 100%;
	}
	
	input[type="text"],
	input[type="email"],
	input[type="submit"],
	textarea {
		position: relative;
		-webkit-appearance: none;
		color: #000;
	}

	input[type="text"],
	input[type="email"] {
		height: 32px;
		width: 95% !important;
		/* width: 100% !important; */
		padding: 5px;
		margin-bottom: 10px;
		border: 1px solid rgb(165, 165, 165);
		border-radius: 5px;
	}

	textarea {
		/* width: 97%  !important; */
		width: 95%  !important;
		/* font-size: 12px; */
		resize: none;
		height: 110px;
		padding: 5px;
		border: 1px solid rgb(165, 165, 165);
		border-radius: 5px;	
		margin: 0 0 20px 0;	
	}

	/* Buttons Submit & Reset */
	input[type="submit"], input[type="reset"] {
		height: 50px;
		border-color: transparent;
		border-radius: 5px;
		font-size: 14px !important;
		font-family: Ubuntu, Arial, Verdana;
		color: #fff;
	}
			input[type="submit"]:hover, input[type="reset"]:hover {
				cursor: pointer;
				font-size: 17px !important;
				text-shadow: 0 1px 2px #000;
			}
			input[type="submit"] {
				width: 78%;
				background: rgb(82, 83, 85);
				float: right;
				border: 1px solid back;
			}

			input[type="reset"] {
				left: 0;
				width: 20%;
				background: rgb(136, 33, 33);
			}
			#aaaReset{ background: rgb(25, 25, 34) center no-repeat url('../../img/misc/refresh-icon.png');}

	/* PlaceHolders */
	::-webkit-input-placeholder,
	:-moz-placeholder,
	::-moz-placeholder,
	:-ms-input-placeholder {
		padding: 5px;
		color: #6d6d6d;
	}
	
	@media (orientation: landscape){
		@media(max-width: 1024px){
		  /* input[type="text"], input[type="email"] { width: 95%; } */
		}	
		@media (min-width: 500px){
		  input[type="text"], input[type="email"] { width: 95%; }
		  textarea { width: 97%; }
		  /* input[type="reset"], input[type="submit"] { font-size: 12px;  } */
		}	
	}	

}

/* Google Map */
@media screen {
	#aaa_map {
		position: absolute;
		float: right;
		top: 5px;
		right: 5px;
		width: 75px;
		height: 75px;
		background-image: url("../../img/misc/aaa_map.jpg");
		border: 1px solid rgb(150, 199, 255);
		border-radius: 5px;
		/* box-shadow: 0 0 17px -3px #000; */
		z-index: 1;
	}

	#aaa_map:hover {
		cursor: pointer;
	}
	
}	

/* NEW */
@media only screen {
    #aaa_contact0{
        position: relative;
        top: 0; 
        width: 98%; 
        height: 531px;
        margin: auto !important; 
    }

    @media only screen {
        @media (orientation: portrait) {
            @media (min-width: 481px) {
                #aaa_contact0{ width: 80% !important; max-width: 500px; height: 615px; }
            }
        }
        @media (orientation: landscape) {
            @media (min-width: 501px) {
                #aaa_contact0{ max-width: 500px; height: 615px !important; }
            }            
        }
    }


}

@media screen {
	#aaa_emailContainer{
		z-index: 5;
		/* background: rgba(0,0,0,0.8); */
		/* background: rgba(255,255,255,0.4); */
		padding: 100px 0 0 0;
	}
	.closeEmailOverlay{
		position: absolute;
		right: 3px;
		top: 3px;
		width: 24px;
		height: 24px;
		cursor: pointer;
		border: 3px solid #f00;
		box-shadow: 0 0 3px #000, inset 0 0 1px #555;
		border-radius: 50%;
		background: #d00	;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
}
	.hideemailoverlay{
		opacity: 0;
		transform: scale3d(0.95,0.95,0);
		-webkit-transform: scale3d(0.95,0.95,0);
		-moz-transform: scale3d(0.95,0.95,0);
		-ms-transform: scale3d(0.95,0.95,0);
		-o-transform: scale3d(0.95,0.95,0);
}

}