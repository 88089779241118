.figureTitle{
    position: relative;
    text-align: center !important;
    font: 14px Arial, Helvetica;
    color: black;
    margin-top: 10px;
}

.noDecor{
    text-decoration: none;
}