/* #mainContainer {
  scroll-behavior: smooth;
} */

#aaaMiddle{
  /* top: 80px; 
  width: 80%; 
  height: auto; 
  padding: 5px;*/

  position: relative;
  left: 0;
  margin: auto;
  min-width: 310px;

  /* modifications if slider is in iframe */
  top: 0; 
  width: 100%;
  height: 100%;
  padding: 0;
}

#aaa_scrollUp {
  position: fixed;
  top: 50%;
  right: 0;
  width: 42px;
  height: 28px;
  background: no-repeat 0% center url(img/misc/aaa_scrollUp.png);
  cursor: pointer;
  z-index: 10;
  display: none;
}

@media screen {
  @media (min-width: 570px){
    /* #aaaMiddle{ width: 85%; }     */
  }
  @media (orientation: landscape) and (min-width: 570px){
    /* #aaaMiddle{ width: 90%; }     */
  }
  @media (min-width: 800px){
    /* #aaaMiddle{ max-width: 1160px; }     */
  }
}