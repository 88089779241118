
#aaaHeader{
    position: fixed;
    z-index: 10;
    top: 0; left: 0;
    width: 100%;
    height: 80px;
    background: rgba(255,255,255,0.7);
    /* background: rgba(0,0,0,0.2); */
    
    
}

#aaaMenu{
    position: relative;
    top: 80px; height: auto;
    left: 0;
    width: 100px;
    background: rgba(255,255,255,0.7);
    text-align: center;
    border-radius: 2px;
}
#aaa3Bar{
    position: absolute;
    float: left;
    top: 28px;
    left: 20px;
    width: 24px;
    height: 24px;
    /* background-image: url(../../img/misc/wlc3bars.png);     */
}

.aaaNavlink{
    color: rgb(0, 0, 0); 
    /* text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3); */
    float: left;
    width: 100%;
    margin: 0 0 15px 0 !important;
    text-decoration: none !important;
    font: 14px/18px Arial, Helvetica, Sans-serif;
    cursor: pointer;
    padding: 0 3px;
    transition: all 300ms linear;
}
.aaaNavlink:hover{ color: #f2f2f2; background: rgba(3, 3, 3, 0.183); text-shadow: none; }

#flags{
    position: relative;
    width: 90px;
    height: 20px;
    top: 0px; 
    left: 50px; 
    transform: translateX(-50%);
    display: flex;
    row-gap: 10px;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 15px;
    /* border: 1px solid red; */

}
#flags div{ width: 30px; cursor: pointer; }
#english{ background-image: url('../../img/misc/ukflagSq.png'); background-size: Cover; background-position: center; }
#portugal{ background-image: url('../../img/misc/portugalflagSq.png'); background-size: Cover; background-position: center; }


#aaaLogo {
    position: absolute;
    float: right;
    /* top: 15px; right: 20px; height: 61px;*/
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    text-align: center;
}
#aaaLogo img{ position: relative; top: 10px !important; }

.toogleMenu{
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    -o-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}

@media only screen {
    /* @media (min-width: 768px){ */
    @media (min-width: 1111px){
        #aaaMenu{ /* top: 34px; */ top: 0; height: 100% !important; width: 700px; margin-left: 10px; background: transparent; }
        #aaa3Bar{ display: none; }
        .aaaNavlink{ width: auto; margin: 0 3px !important; font: 14px/80px Arial, Helvetica, Sans-serif;}
        .toogleMenu{
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
        #flags { top: 30px; left: 40px; }
    }

    /* @media (min-width: 1157px) {
        #flags{ left: 630px; }
    } */

}