body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
  scrollbar-width: none;
}

div, form, span, iframe, figure {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  /*  stop browser asking copy/save/select image or whatever.  */
    -webkit-user-select: none;
    user-select: none;
  -webkit-touch-callout: none;
  /* make sure all text is working well */
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  /* do NOT use this: introduces a strange flicker in iPad when in Mozaic !!!
     -webkit-perspective: 1000;
     -webkit-backface-visibility: hidden;*/
  /* When working with 3d transform always Add these properties to prevent flickering effects: */
      /* -webkit-transform-style: preserve-3d;  */
      /* -webkit-perspective: 1000; */
  -webkit-backface-visibility: hidden; 
  backface-visibility: hidden; 

  /*When TAPping on iPad, an unwanted gray-blink appears; this is to make that blink transparent so it's not visible!*/
  -webkit-tap-highlight-color: transparent; 

  /* for Windows = autohide el scrollbar: https://developer.mozilla.org/en-US/docs/Web/CSS/-ms-overflow-style  */
  /* -ms-overflow-style: -ms-autohiding-scrollbar; */
  /* WINDOWS 10 = DO NOT SHOW SCROLLBAR */
  -ms-overflow-style: none;

  /* for FireFox > v64 */
  scrollbar-width: none;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.clearfix{ overflow: auto; }


iframe {
  border: 0;
  width: 100%;
  height: 100%;
}

.universalContainer{ position: absolute; width: 100%; }
.exanet_absT0L0W100H100M0P0{ position: absolute; top: 0; left: 0; width: 100%; height: 100%; margin: 0; padding: 0; }
.exanet_absT0R0W100H100M0P0{ position: absolute; top: 0; right:0; width: 100%; height: 100%; margin: 0; padding: 0; }
.exanet_absT0L0W100M0P0{     position: absolute; top: 0; left: 0; width: 100%; margin: 0; padding: 0; }	
.exanet_relT0L0W100H100M0P0{ position: relative; top: 0; left: 0; width: 100%; height: 100%; margin: 0; padding: 0; }
.exanet_relT0R0W100H100M0P0{ position: relative; top: 0; right: 0; width: 100%; height: 100%; margin: 0; padding: 0; }
.exanet_relT0L0W100M0P0{     position: relative; top: 0; left: 0; width: 100%; margin: 0; padding: 0; }
.exanet_relT0L0W100{         position: relative; top: 0; left: 0; width: 100%; }
.exanet_rel{                 position: relative !important; }

.cssHide{ display: none; }
.notVisible{ opacity: 0; visibility: hidden; }


figure{
  /* margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 5px;
  margin-inline-end: 5px; */
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding: 0;
  margin: 0 !important;
}
.responsive{ width: 100%; height: auto; }
/* .responsive{ width: 100%; height: 100%; } */
/* .responsive{ width: auto; height: 100%; } */

/* TRANSITIONS */
.T200{
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}
.T300{
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}
.T400{
  -webkit-transition: all 400ms linear;
  -moz-transition: all 400ms linear;
  -ms-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}
.T600{
  -webkit-transition: all 600ms linear;
  -moz-transition: all 600ms linear;
  -ms-transition: all 600ms linear;
  -o-transition: all 600ms linear;
  transition: all 600ms linear;
}
.T800{
  -webkit-transition: all 800ms linear;
  -moz-transition: all 800ms linear;
  -ms-transition: all 800ms linear;
  -o-transition: all 800ms linear;
  transition: all 800ms linear;
}

.goggleOpenSans{
  font: 28px 'Open Sans Condensed', sans-serif, Arial, Helvetica;
}



.gradient1 {
  background: -moz-linear-gradient(left, white 0%, white 17%, rgba(255, 255, 255, 0.28) 77%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(left, white 0%, white 17%, rgba(255, 255, 255, 0.28) 77%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, white 0%, white 17%, rgba(255, 255, 255, 0.28) 77%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
}

.centered2 {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.aaaTransOrigCenter {
  -ms-transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -mz-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.blurry{
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -ms-filter: blur(5px);
  -o-filter: blur(5px);
  filter: blur(5px);
}

/* FORMS - input */
@media only screen {
	input {
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		-o-appearance: none;
		appearance: none;
	}

	input:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px #eeeeff inset;
		box-shadow: 0 0 0px 1000px #eeeeff inset;
		-webkit-text-fill-color: black !important;
	}

	::-webkit-input-placeholder { color: #777; font-size: 11px;  }
	::-moz-placeholder { color: #777; font-size: 11px;  }
	:-ms-input-placeholder { color: #777; font-size: 11px;  }
	::placeholder { color: #777; font-size: 11px;  }

}


/*ScrollBAR customization for WebKit*/
@media only screen{
		
	/*the main scrollbar container*/
	::-webkit-scrollbar {
	    width: 0;
	    background-color: transparent;
	}
	
	/*the main track of the scollbar.*/
	::-webkit-scrollbar-track {
	    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);*/
	    background-color: transparent;
	}
	
	/*the thumb of the scrollbar*/
	::-webkit-scrollbar-thumb {
	    background-color: transparent;
  }	
  
  .exanet_Scroller{ overflow-x: hidden; overflow-y: scroll;  -webkit-overflow-scrolling: touch; }
}



/*BACKGROUND-IMAGE SIZES*/
@media only screen{
	.aaaBGCover{
    background-repeat: no-repeat;		
    background-position: center;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
		background-size: cover;  

		-webkit-object-fit: cover;
		-moz-object-fit: cover;
		-ms-object-fit: cover;
		-o-object-fit: cover;
		object-fit: cover;
	}
	.aaaBGauto100{
    background-repeat: no-repeat;		
		background-position: center;
		-moz-background-size: auto 100%;
		-webkit-background-size: auto 100%;
		-ms-background-size: auto 100%;
		-o-background-size: auto 100%;
		background-size: auto 100%;
	}	
	.aaaBG100auto{
    background-repeat: no-repeat;		
		background-position: center;
		-moz-background-size: 100% auto;
		-webkit-background-size: 100% auto;
		-ms-background-size: 100% auto;
		-o-background-size: 100% auto;
		background-size: 100% auto;
	}	
	.aaaBG100100{
	    background-repeat: no-repeat;
		background-position: center;
		-moz-background-size: 100% 100%;
		-webkit-background-size: 100% 100%;
		-ms-background-size: 100% 100%;
		-o-background-size: 100% 100%;
		background-size: 100% 100%;
	}
 
	.aaaBGauto80:hover{ background-color:  rgba(0,0,0,1); }

    div.bg100100{
        background-position: center;
        background-repeat: no-repeat;
        -moz-background-size:    100% 100%;
        -webkit-background-size: 100% 100%;
        -ms-background-size:     100% 100%;
        -o-background-size:      100% 100%;
        background-size:         100% 100%;
    }
    div.bgauto100{
        background-position: center;
        background-repeat: no-repeat;
        -moz-background-size:    auto 100%;
        -webkit-background-size: auto 100%;
        -ms-background-size:     auto 100%;
        -o-background-size:      auto 100%;
        background-size:         auto 100%;
    }
    div.bgcover{
        background-position: center;
        background-repeat: no-repeat;
        -moz-background-size:    cover;
        -webkit-background-size: cover;
        -ms-background-size:     cover;
        -o-background-size:      cover;
        background-size:         cover;
    }
    div.bgcover2{
        background-position: center 75%;
        background-repeat: no-repeat;
        -moz-background-size:    cover;
        -webkit-background-size: cover;
        -ms-background-size:     cover;
        -o-background-size:      cover;
        background-size:         cover;
    }	

}

.BGCover{
  background-size: Cover;
}

.BG100100{
  background-size: 100% 100%;
}




.borderLine {
  position: relative;
  top: 2px;
  left: 0;
  width: 100%;
  height: 6px;
  border-top: 3px solid black;
  border-bottom: 1px solid black;
}



.borderLine1 {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    border-top: 1px solid #777;
}


.zoomIN{
  -moz-transform: scale3d(3,3,0);
  -webkit-transform: scale3d(3,3,0);
  -o-transform: scale3d(3,3,0);
  -ms-transform: scale3d(3,3,0);
  transform: scale3d(3,3,0);
  filter: progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=3, M12=-3, M21=3, M22=3);
}

.zoomOut {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transform: scale3d(0, 0, 0);
  -webkit-transform: scale3d(0, 0, 0);
  -o-transform: scale3d(0, 0, 0);
  -ms-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
  filter: progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=0, M12=-0, M21=0, M22=0);
}

.exanetAnim1{
  position: relative;
  /* -webkit-animation: exanet_Anim1 1.5s;
  -moz-animation: exanet_Anim1 1.5s;
  -ms-animation: exanet_Anim1 1.5s;
  -o-animation: exanet_Anim1 1.5s;   */
  animation: exanet_Anim1 1s;
}
.exanetAnim2{
  position: relative;
  animation: exanet_Anim2 1.5s;
}


@keyframes exanet_Anim1 {
  from   {  opacity: 0; transform: translate(0, 10px); }
  to {  opacity: 1; transform: translate(0, 0); }
}
@keyframes exanet_Anim2 {
  from   {  opacity: 0; transform: scale(0.99, 0.99); }
  to {  opacity: 1; transform: scale(1,1); }
}

.animEntrance{
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  animation: entrance 300ms ease-in;
}

@keyframes entrance {
  0%{ opacity: 0; transform: translate3d(0,15px,0) }
  100%{ opacity: 1; transform: translate3d(0,0,0)  }
}






