/* Google Map */
@media screen {

	#contactOverlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 22px;
		background: rgba(255, 255, 255, 0.7);
		z-index: 101;
	}
	
	#googleMapContainer {
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	#googleMapClose {
		position: absolute;
		top: 90px;
		right: 24px;
		float: right;
		width: 48px;
		height: 48px;
		background: no-repeat center url("../../img/misc/close.png");
		cursor: pointer;
	}

	#aaa_waitClock {
		position: absolute;
		width: 32px;
		height: 32px;
		bottom: 19%;
		right: 5%;
		background: no-repeat center url("../../img/misc/aaa_waitClock.gif");
	}
	
}	