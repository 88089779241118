

.font14{ font-size: 16px; font-weight: bold; }
.cursordefault {cursor: default }




@media only screen{
    #aaa_VolunteeringContainer{
        position: relative;
        margin-top: 100px;
    }

    div[id^="aaa_volunteer"] figure{
        position: relative;
        top: -13px
    }

    #aaa_volunteeringFrame{
        position: relative;
        bottom: 0; margin: auto; height: 700px; max-width: 965px;
        /* border: 1px solid red; */
    }

    #aaa_volContent{
        position: absolute;
        top: 0; left: 0; 
        height: auto; 
        width: 100%;
        text-align: center;
        overflow: hidden;
    }
    #aaa_volContent figcaption {
        color: rgb(65, 65, 65);
    }
        #aaa_volContent:hover  #volunteeringText{
            -moz-transform: translate3d(0,0,0);
            -webkit-transform: translate3d(0,0,0);
            -o-transform: translate3d(0,0,0);
            -ms-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);        
        }
        #volunteeringText{
            position: absolute;
            top: 0; left: 0; width: 35%; height: 100%;
            background: rgba(0,0,0,0.65);
            -moz-transform: translate3d(-100%,0,0);
            -webkit-transform: translate3d(-100%,0,0);
            -o-transform: translate3d(-100%,0,0);
            -ms-transform: translate3d(-100%,0,0);
            transform: translate3d(-100%,0,0);
        }



    img[id^="aaa_volunt"]{
        position: relative;
        top: 0; margin: auto;
        width: 100%; height: auto; 
        max-height: 640px;
        max-width: 965px;
        cursor: auto;
        vertical-align: top;
    }
           

    #volunteerNavigation{
        position: relative;
        top: 0; width: 100px; height: 38px;
        margin: auto;
    }
    #voluntLeft, #voluntRight{
        position: relative; top: 2px; width: 32px; height: 38px;
        cursor: pointer;
    }
    #voluntLeft{ float: left; background: no-repeat center url('../../img/misc/journal_prev2.png'); }
    #voluntRight{ float: right; background: no-repeat center url('../../img/misc/journal_next2.png');}
    
    .voluntZoomOut{
        -moz-transform: scale3d(0.95,0.95,1);
        -webkit-transform: scale3d(0.95,0.95,1);
        -o-transform: scale3d(0.95,0.95,1);
        -ms-transform: scale3d(0.95,0.95,1);
        transform: scale3d(0.95,0.95,1);
        /* filter: progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=0, M12=-0, M21=0, M22=0); */
        opacity: 0;
    }

    .Vcol2 {
        position: relative;
        min-height: 1px;
        padding: 7px 0 !important;
        height: auto;
    }
      .Vcol2L { float: left !important; }
      .Vcol2R { float: right !important; font: 13px/20px Ubuntu, Arial, Helvetica;}
      .Vcol3 {
        position: relative;
        min-height: 1px;
        padding: 0 !important;
        height: auto;
        top: -4px;
    }


    @media only screen{
        .col2sizeL{ width: 100%; }
        .col2sizeR{ width: 100%; }

        @media (orientation: landscape){
            @media (min-width: 400px){
                .col2sizeL{ width: 40%; }
                .col2sizeR{ width: 58%; }
            }
            @media (min-width: 680px){
                .col2sizeL{ width: 20%; }
                .col2sizeR{ width: 78%; }
            }            
        }
        @media (orientation: portrait){
            @media (min-width: 400px){
                .col2sizeL{ width: 40%; }
                .col2sizeR{ width: 58%; }
            }
            @media (min-width: 680px){
                .col2sizeL{ width: 20%; }
                .col2sizeR{ width: 78%; }
            }
        }
    }      
   

}

.Vcol3 strong{
    font-weight: 900;
}