
#portfolioMenu{
    position: relative;
    margin-top: 100px;
}

.imgContainer{
    position: relative;
    border: 4px solid white;
}

.imgContainer:hover figure div{
    opacity: 1
 }

.imgContainer figure div{
   background: rgba(0,0,0,0.75);
   position: absolute;
   width: 100%; height: 50px;
   bottom: 3px;
   opacity: 0;
   color: white;
   text-align: right;
   font: 13px/50px Arial, Helvetica;
   padding: 0 10px 0 0;
}
.imgContainer2{
    position: relative;
    border: 4px solid white;
}

/* .imgContainer:hover figure div{
    opacity: 1
 }

.imgContainer figure div{
   background: rgba(0,0,0,0.75);
   position: absolute;
   width: 100%; height: 50px;
   bottom: 3px;
   opacity: 0;
   color: white;
   text-align: right;
   font: 13px/50px Arial, Helvetica;
   padding: 0 10px 0 0;
} */

.projectTitle{
    position: absolute;
    top: 10px; 
    right: 10px;
    width: 120px; height: 50px;
    background: rgba(0,0,0,0.75);
    text-decoration: none;
    color: white;
    font: 14px/50px Arial, Helvetica;
    text-align: center;
}

.categoryTitle{
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    margin-top:  100px;
    text-align: right;
}


@media screen {
    @media (min-width: 560px ){
        .imgContainer, .imgContainer2{
            float: left;
            width: 33.33%;
        }
        /* .projectTitle{
            top: 10px;
        } */

    }
}