/*FONTS*/
/* @media only screen{ */
/* @font-face rules MUST NOT be contained in media queries: do not work in IE, Edge, */
/* some fonts (woff[2]) needs to have the LICENCE file available to be loaded by MS Edge !!!  */
@font-face {
    font-family: 'PoiretOne';
    font-display: auto;
    src: url("../../fonts/PoiretOne-Regular.ttf") format("ttf");
    src: local("PoiretOne"), url("../../fonts/PoiretOne-Regular.woff2") format("woff"), url("../../fonts/PoiretOne-Regular.ttf") format("truetype"), url("../../fonts/PoiretOne-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/PoiretOne-Regular.woff") format("woff"), url("../../fonts/PoiretOne-Regular.svg#PoiretOne") format("svg");
  }
  @font-face {
  font-family: 'Cumulus';
  src: url("../../fonts/cumulus-webfont.woff2") format("woff2");
  src: local("☺"), url("../../fonts/cumulus-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/cumulus-webfont.woff2") format("woff2"), url("../../fonts/cumulus-webfont.woff") format("woff"), url("../../fonts/cumulus-webfont.ttf") format("truetype"), url("../../fonts/cumulus-webfont.svg#cumuluslight") format("svg");
  }
  @font-face {
  font-family: 'Labtop';
  src: url("../../fonts/labtopi_-webfont.woff2") format("woff2");
  src: local("☺"), url("../../fonts/labtopi_-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/labtopi_-webfont.woff2") format("woff2"), url("../../fonts/labtopi_-webfont.woff") format("woff"), url("../../fonts/labtopi_-webfont.ttf") format("truetype"), url("../../fonts/labtopi_-webfont.svg#labtopitalic") format("svg");
  }
  @font-face {
  font-family: 'LabtopRegular';
  src: url("../../fonts/labtop__-webfont.woff2") format("woff2");
  src: local("☺"), url("../../fonts/labtop__-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/labtop__-webfont.woff2") format("woff2"), url("../../fonts/labtop__-webfont.woff") format("woff"), url("../../fonts/labtop__-webfont.ttf") format("truetype"), url("../../fonts/labtop__-webfont.svg#labtopregular") format("svg");
  }
  @font-face {
  font-family: 'Edition';
  src: url("../../fonts/edition_-webfont.woff2") format("woff2");
  src: local("☺"), url("../../fonts/edition_-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/edition_-webfont.woff2") format("woff2"), url("../../fonts/edition_-webfont.woff") format("woff"), url("../../fonts/edition_-webfont.ttf") format("truetype"), url("../../fonts/edition_-webfont.svg#editionregular") format("svg");
  }
  @font-face {
  font-family: 'Steiner';
  src: url("../../fonts/steinerlight-webfont.woff2") format("woff2");
  src: local("☺"), url("../../fonts/steinerlight-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/steinerlight-webfont.woff2") format("woff2"), url("../../fonts/steinerlight-webfont.woff") format("woff"), url("../../fonts/steinerlight-webfont.ttf") format("truetype"), url("../../fonts/steinerlight-webfont.svg#steinerregular") format("svg");
  }
  @font-face {
  font-family: 'oswaldlight';
  src: url("../../fonts/oswald-light-webfont.woff2") format("woff2");
  src: local("☺"), url("../../fonts/oswald-light-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/oswald-light-webfont.woff2") format("woff2"), url("../../fonts/oswald-light-webfont.woff") format("woff"), url("../../fonts/oswald-light-webfont.ttf") format("truetype"), url("../../fonts/oswald-light-webfont.svg#oswaldlight") format("svg");
  }
  @font-face {
  font-family: 'bikolight';
  src: url("../../fonts/biko_light-restricted-webfont.woff2") format("woff2");
  src: local("☺"), url("../../fonts/biko_light-restricted-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/biko_light-restricted-webfont.woff2") format("woff2"), url("../../fonts/biko_light-restricted-webfont.woff") format("woff"), url("../../fonts/biko_light-restricted-webfont.ttf") format("truetype"), url("../../fonts/biko_light-restricted-webfont.svg#bikolight") format("svg");
  }
  @font-face {
  font-family: 'Ubuntu';
  src: url("../../fonts/ubuntu-l-webfont.woff2") format("woff2");
  src: local("☺"), url("../../fonts/ubuntu-l-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/ubuntu-l-webfont.woff2") format("woff2"), url("../../fonts/ubuntu-l-webfont.woff") format("woff"), url("../../fonts/ubuntu-l-webfont.ttf") format("truetype"), url("../../fonts/ubuntu-l-webfont.svg#ubuntulight") format("svg");
  }	
  @font-face {
  font-family: 'helveneue';
  src: url("../../fonts/HelveticaNeue.ttf") format("truetype");
  src: local("☺"), url("../../fonts/HelveticaNeue.ttf") format("truetype");
  }	
  @font-face {
  font-family: 'helveneuethin';
  src: url("../../fonts/HelveticaNeueThn.ttf") format("truetype");
  src: local("☺"), url("../../fonts/HelveticaNeueThn.ttf") format("truetype");
  }	
  
  @font-face {
    font-family:"UbuntuLight";
    src:url("../../fonts/Ubuntu-Light.eot?") format("eot"),url("../../fonts/Ubuntu-Light.woff") format("woff"),url("../../fonts/Ubuntu-Light.ttf") format("truetype"),url("../../fonts/Ubuntu-Light.svg#Ubuntu-Light") format("svg");
  }