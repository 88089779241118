.sliderContainer{
  overflow: hidden;
}


/*Downloaded from https://www.codeseek.co/digistate/horizontal-parallax-sliding-slider-with-swiperjs-aEqzBB */
[class^="swiper-button-"] {
  transition: all .3s ease;
}

.swiper-slide {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.swiper-container {
  /* XAVIER width: 80%; */
  width: 100%;
  height: 100vh;
  float: left;
  transition: opacity .6s ease, -webkit-transform .3s ease;
  transition: opacity .6s ease, transform .3s ease;
  transition: opacity .6s ease, transform .3s ease, -webkit-transform .3s ease;
}
.swiper-container.nav-slider {
  width: 20%;
  padding-left: 5px;
}
.swiper-container.nav-slider .swiper-slide {
  cursor: pointer;
  opacity: .4;
  transition: opacity .3s ease;
}
.swiper-container.nav-slider .swiper-slide.swiper-slide-active {
  opacity: 1;
}
.swiper-container.nav-slider .swiper-slide .content {
  width: 100%;
}
.swiper-container.nav-slider .swiper-slide .content .title {
  font-size: 20px;
}
.swiper-container:hover .swiper-button-prev,
.swiper-container:hover .swiper-button-next {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
  visibility: visible;
}
.swiper-container.loading {
  /* opacity: 0;
  visibility: hidden; */
  opacity: 1
}

.swiper-slide {
  overflow: hidden;
}
.swiper-slide .slide-bgimg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}
.swiper-slide .entity-img {
  display: none;
}
.swiper-slide .content {
  position: absolute;
  /* Xavier top: 40%; */
  top: 57%;
  /* background: rgba(0,0,0,0.65); */
  background: rgba(255,255,255,0.65);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  left: 0;
  width: 45%;
  min-width: 320px;
  max-width: 750px;
  /* Xavier padding-left: 5%; */
  padding: 30px 0;
  color: #000;
  /* text-shadow: 0 1px 1px #000; */
  text-justify: center;
}
.swiper-slide .content .title {
  /* font-size: 2.2em; */
  font-size: calc(24px + 3 * ((100vw - 320px) / 680));
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 10px;
  text-align: center !important;
}
.swiper-slide .content .caption {
  /* Xavier 30May2020 */
  display: block;
  /* font-size: 20px; */
  font-size: calc(14px + 1.5 * ((100vw - 320px) / 680));
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.6;
  text-align: center !important;
  -webkit-transform: translateX(50px);
          transform: translateX(50px);
  opacity: 0;
  transition: opacity .3s ease, -webkit-transform .7s ease;
  transition: opacity .3s ease, transform .7s ease;
  transition: opacity .3s ease, transform .7s ease, -webkit-transform .7s ease;
}
.swiper-slide .content .caption.show {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
}

[class^="swiper-button-"] {
  width: 44px;
  opacity: 0;
  visibility: hidden;
}

.swiper-button-prev {
  -webkit-transform: translateX(50px);
          transform: translateX(50px);
}

.swiper-button-next {
  -webkit-transform: translateX(-50px);
          transform: translateX(-50px);
}
