#aaa_oficinasContainer {
    position: relative;
    top: 90px;
    width: 100%;
    height: 750px;
    text-align: center;
    /* border: 1px solid red; */
}
#aaa_oficinasContainer img { max-width: 1000px;}
.encabezado { 
    height: 60px;
    width: 100%; text-align: left !important; 
    font: bold 46px Helvetica, Verdana, Arial; 
    border-bottom: 1px solid gray;
    margin-bottom: 50px;
}


#infocontacto{
    position: relative;
    top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

#infopty, #infopt{
    width: 400px;
    min-width: 300px;
    font: 15px/23px Ubuntu, Helvetica, Verdana, Arial;
    /* border: 3px solid rgba(0,0,0,0.1); */
    border-radius: 7px;
    padding: 10px;
    margin-bottom: 7px;
    text-align: left;
}
#infopty strong, #infopt strong{ font-weight: bold; font-family: Ubuntu, Helvetica, Verdana, Arial; font-size: 16px;}
#infopty em, #infopt em { font-style: italic; color: rgb(0, 126, 253) }

.clock {
    position: relative;
    top: 50%;
    margin-top: 20px;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #898c8d;
    font-size: 25px;
    font-family: Orbitron;
    letter-spacing: 3px;
}