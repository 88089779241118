#aaa_intro, #aaa_intro2 {
    position: relative;
    top: 0;
    left: 0;
    padding: 0 7px;
    width: 100%;
    font-family: 'UbuntuLight', Arial, Verdana, Helvetica;
    font-weight: 100;
    font-size: calc(12px + 4*((100vw - 300px)/870));
    line-height: calc(24px + 12*((100vw - 300px)/870));
    text-align: justify;
}


.homeImgContainer{
    position: relative;
    float: left;
    width: 100%;
    border: 4px solid white;
    overflow: hidden;
}
.homeImgContainer:hover figure div{
    opacity: 1
 }

.homeImgContainer figure div{
   background: rgba(0,0,0,0.75);
   position: absolute;
   width: 100%; height: 50px;
   bottom: 3px;
   opacity: 0;
   color: white;
   text-align: right;
   font: 13px/50px Arial, Helvetica;
   padding: 0 10px 0 0;
}

.marginBottom{ margin-bottom: 6px; }
.marginTop{ margin-top: 4px; }

.aaaLoader{
    position: relative;
    background: no-repeat center url(../../img/misc/loader1.gif);
    background-size: 28px 28px;
    overflow: hidden;
}


#aaa_mainVideo{
    padding: 0 4px;
}

.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    will-change: opacity;
  }
  .bg_modified{
      border-bottom: 3px solid white;
  }

#setMainText{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%; height: 80px;
    z-index: 10;
}
.tMain{
    font: 15px/20px Georgia, Times;
}
.toggleText{
    transform: translateY(100%)
}

#mainText{
    position: relative;
    top: 0; left: 0; width: 100%; 
    height: 100%; 
    padding: 10px; margin: 0;
    text-align: center;
    font: 13px/20px Arial, Helvetica, Sans-serif;
    color: white;
}

    #mainText span{
        padding: 3px 7px;
        background: rgba(0,0,0,0.85);
    }
    #mainText span.title{
        font-size: 16px !important;
        font-family: Georgia, Times !important;
    }

    #mainText span.date{
        color: #ff5100 !important;
    }

.toTheBottom{
    display: flex; justify-content: center; 
    width: 100%; height: 100%; top: 0; margin: 0; padding: 0; color: white;
    flex-direction: column-reverse;
}


.aaa_link{ text-decoration: none; color: rgb(0, 204, 255); cursor: pointer; }

.aaaBlack{ background: rgba(0,0,0,0.75); }
.aaaWhite{ background: rgba(255,255,255,0.75); }
.aaaNull { background: transparent }

@keyframes xxx{
    from { transform: translateX(100%) }
    to { transform: translateX(0) }
}


@media (min-width: 500px){
    .homeImgContainer{ width: 50%; }
}
@media (orientation: portrait) and (max-width: 575px){
    #setMainText{ height: 55px; }
    .tDscr{ display: none }
}
@media (orientation: landscape) and (max-width: 595px){
    #setMainText{ height: 55px; }
    .tDscr{ display: none }
}



.row{
    display: inline-block;
    width: 100%;
}


.bg100 {
    background-position: fixed;
    background-size: cover;
}

.aaaSlider{
    top: -10px !important;
    background: black
}

.slider{
  position:  relative;
  top:  0;
  width:  100%;
  margin:  auto;
  height:  100%;
  overflow:  hidden;
  z-index:  0;
}

/* HASTA AQUI, TODO BIEN! */



::root{
  --imgPath:  "../../img/main_pic/init";
  --imgPathspl: --imgPath + "/spl";
  --imgPathspp: --imgPath + "/spp";
  --imgPathtbp: --imgPath + "/tbp";
  --imgPathtbl: --imgPath + "/tbl";
}


.slider div[class*="bg100"]{ 
    opacity:  0;
    transition: transform 600ms linear;
    transform-origin: left center 0;
    animation:  fadeAnim1 80s ease-out infinite;
}

.slider2{ animation-delay:  10s !important }  
.slider3{ animation-delay:  20s !important }  
.slider4{ animation-delay:  30s !important }  
.slider5{ animation-delay:  40s !important }  
.slider6{ animation-delay:  50s !important }  
.slider7{ animation-delay:  60s !important }  
.slider8{ animation-delay:  70s !important }  

/* HASTA AQUI, TODO BIEN */

@media (orientation: portrait){
  .slider1{ background-image:  url(../../img/main_pic/init/spp/l1.jpg); }  
  .slider2{ background-image:  url(../../img/main_pic/init/spp/l2.jpg); }  
  .slider3{ background-image:  url(../../img/main_pic/init/spp/l3.jpg); }  
  .slider4{ background-image:  url(../../img/main_pic/init/spp/l4.jpg); }  
  .slider5{ background-image:  url(../../img/main_pic/init/spp/l5.jpg); }  
  .slider6{ background-image:  url(../../img/main_pic/init/spp/l6.jpg); }  
  .slider7{ background-image:  url(../../img/main_pic/init/spp/l7.jpg); }  
  .slider8{ background-image:  url(../../img/main_pic/init/spp/l8.jpg); }  

  @media (min-width: 500px){
    .slider1{ background-image:  url(../../img/main_pic/init/tbp/l1.jpg)  }  
    .slider2{ background-image:  url(../../img/main_pic/init/tbp/l2.jpg); }  
    .slider3{ background-image:  url(../../img/main_pic/init/tbp/l3.jpg); }  
    .slider4{ background-image:  url(../../img/main_pic/init/tbp/l4.jpg); }  
    .slider5{ background-image:  url(../../img/main_pic/init/tbp/l5.jpg); }  
    .slider6{ background-image:  url(../../img/main_pic/init/tbp/l6.jpg); }  
    .slider7{ background-image:  url(../../img/main_pic/init/tbp/l7.jpg); }  
    .slider8{ background-image:  url(../../img/main_pic/init/tbp/l8.jpg); }  
  }  
}




@media (orientation: landscape){
  .slider1{ background-image:  url(../../img/main_pic/init/spl/l1.jpg)  }  
  .slider2{ background-image:  url(../../img/main_pic/init/spl/l2.jpg); }  
  .slider3{ background-image:  url(../../img/main_pic/init/spl/l3.jpg); }  
  .slider4{ background-image:  url(../../img/main_pic/init/spl/l4.jpg); }  
  .slider5{ background-image:  url(../../img/main_pic/init/spl/l5.jpg); }  
  .slider6{ background-image:  url(../../img/main_pic/init/spl/l6.jpg); }  
  .slider7{ background-image:  url(../../img/main_pic/init/spl/l7.jpg); }  
  .slider8{ background-image:  url(../../img/main_pic/init/spl/l8.jpg); }  

  @media (min-width: 750px){
    .slider1{ background-image:  url(../../img/main_pic/init/tbl/l1.jpg); }  
    .slider2{ background-image:  url(../../img/main_pic/init/tbl/l2.jpg); }  
    .slider3{ background-image:  url(../../img/main_pic/init/tbl/l3.jpg); }  
    .slider4{ background-image:  url(../../img/main_pic/init/tbl/l4.jpg); }  
    .slider5{ background-image:  url(../../img/main_pic/init/tbl/l5.jpg); }  
    .slider6{ background-image:  url(../../img/main_pic/init/tbl/l6.jpg); }  
    .slider7{ background-image:  url(../../img/main_pic/init/tbl/l7.jpg); }  
    .slider8{ background-image:  url(../../img/main_pic/init/tbl/l8.jpg); }  
  }
}

@media (min-width: 1050px){
  .slider1{ background-image:  url(../../img/main_pic/init/l1.jpg)  }  
  .slider2{ background-image:  url(../../img/main_pic/init/l2.jpg); }  
  .slider3{ background-image:  url(../../img/main_pic/init/l3.jpg); }  
  .slider4{ background-image:  url(../../img/main_pic/init/l4.jpg); }  
  .slider5{ background-image:  url(../../img/main_pic/init/l5.jpg); }  
  .slider6{ background-image:  url(../../img/main_pic/init/l6.jpg); }  
  .slider7{ background-image:  url(../../img/main_pic/init/l7.jpg); }  
  .slider8{ background-image:  url(../../img/main_pic/init/l8.jpg); }  
}



@keyframes fadeAnim1z{ 
  0% { opacity: 0 }
  0.5% { opacity: 1 }
  16% { opacity: 1 }
  16.5% { opacity: 0 }
  100% { opacity: 0 }
}

@keyframes xfadeAnim1{ 
  0%{  
    opacity: 0; 
    transform: translate3d(0,-100%,0) }
  1%{  
    opacity: 1 ;
    transform: translate3d(0,0,0) }
  16%{  
    opacity: 1 ;
    transform: translate3d(0,0,0) }
  17%{  
    opacity: 0 ;
    transform: translate3d(0,-100%,0) }
  100%{  
    opacity: 0 ;
    transform: translate3d(0,-100%,0)}
}



:root{
  --aaascale: 0.96;
}


@keyframes xxfadeAnim1{ 
  0%{  
    opacity: 0; 
    transform: scale(var(--aaascale)) }
  1%{  
    opacity: 1 ;
    transform: scale(1) }
  12.6%{  
    opacity: 1 ;
    transform: scale(1) }
  13%{  
    opacity: 0 ;
    transform: scale(var(--aaascale)) }
  100%{  
    opacity: 0 ;
    transform: scale(var(--aaascale)) }
}


@keyframes fadeAnim1{ 
  0%{  
    opacity: 0.5; z-index: 2;
    transform: translate3d(95%,0,0); }
  1%{  
    opacity: 1 ; z-index: 2;
    transform: translate3d(0,0,0) }
  12.5%{  
    opacity: 1 ; z-index: 2;
    transform: translate3d(0,0,0) }
  13%{  
    opacity: 1 ; z-index: -1;
    transform: translate3d(0,0,0)
  }
  14%{
    opacity: 0;  z-index: -1; visibility: hidden;
    transform: translate3d(-50%,0,0) 
  }  
  15%{
    opacity: 0;  z-index: -1; visibility: hidden;
    transform: translate3d(-100%,0,0) 
  }  
  100%{  
    opacity: 0 ; z-index: -1; visibility: hidden;
    transform: translate3d(-100%,0,0)  }
}


.tone{ 
    background: rgba(255,255,255,0.15)
}

.maincontact{
    position: relative;
    margin: 30px auto !important;
    font: 13px/23px Arial;
    text-align: center;
}
.maincontact span{ font-size: 20px}

.slidertext{
  position: relative;
  top: 65%;
  left: 0;
  width: 45%;
  height: auto;
  padding: 30px 5%;
  background: rgba(255,255,255,0.78);
  opacity: 1 !important;
  color: black;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  animation: slideranim 500ms ease-out;
}
@keyframes slideranim{
  0%{ opacity: 0; transform: translate3d(-25%,0,0) }
  100%{ opacity: 1; transform: translate3d(0,0,0)}
}

.slidertextTitle{ 
  font: bold 35px/28px Georgia, Times; 
  margin: 0 0 20px 0;
}
.slidertextDesc{
  font: 14px/22px Helvetica, Arial;
  transform-style: preserve-3d;
  animation: sliderdesc 80s linear infinite;
}



.a2 { animation-delay:  10s; }
.a3 { animation-delay:  20s; }
.a4 { animation-delay:  30s; }
.a5 { animation-delay:  40s; }
.a6 { animation-delay:  50s; }
.a7 { animation-delay:  60s; }
.a8 { animation-delay:  70s; }
  
@keyframes sliderdesc{ 
  0%{  
    opacity: 0; 
    transform: translate3d(37%,0,0);
  }
  1%{  
    opacity: 0; 
    transform: translate3d(37%,0,0);
  }
  1.5%{  
    opacity: 0.5 ;
    transform: translate3d(0,0,0);
  }
  4%{
    opacity: 1 ;
    transform: translate3d(0,0,0);
  }
  100%{  
    opacity: 1 ;
    transform: translate3d(0,0,0);
  }

}

/* HASTA AQUI, BIEN */


.prize{
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../img/main_pic/init/l1x.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: Cover;
}

#premio{
  position: relative;
  width: 50%;
  min-width: 575px;
  height: 120px;
  top: 78%;
  left: 0;
  background: rgba(0,0,0,0.75);
  padding: 20px;
}
#premio span{ font-family:  'Arial Narrow Bold', sans-serif; color: white; line-height: 28px;}
#premio span:nth-child(1){ font-size: 24px; }
#premio span:nth-child(3){ font-size: 21px; }
#premio span:nth-child(2){ font-size: 15px; }

@media only screen{
  @media (max-width: 570px){
    #premio span:nth-child(1){ font-size: 18px; }
    #premio span:nth-child(3){ font-size: 17px; }
    #premio span:nth-child(2){ font-size: 14px; }
  }    
  @media (max-width: 425px){
    #premio{ height: 110px !important; }
    #premio span{ line-height: 24px !important;}
    #premio span:nth-child(1){ font-size: 15px; }
    #premio span:nth-child(3){ font-size: 14px; }
    #premio span:nth-child(2){ font-size: 12px; }
  }    
  

} 
