#aaaFooter{
    position: relative;
    top: 20px; left: 0;
    width: 100%;
    height: auto;
    margin: 50px auto 5px auto;
    padding: 0 15% 20px 15%;
    display: flex;
    flex-direction: column;
}

.footerContainer{
    display: flex;
    flex-direction: column;
}

div[id^="aaa_socnetIcons"]{
    position: relative;
    top: 0; left: 0;
    /* width: 164px; */
    height: auto;  
    margin: auto;
    padding: 7px;
    flex-grow: 3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
div[id^="aaa_socnetIcons"] div{
    position: relative;
    top: 0; left: 0;
    width: 55px;
    height: 55px; 
    border-radius: 50%;
    border: 1px solid #ddd;
    transition: 0.5s;
    cursor: pointer;
    margin: 5px;
}

@media (min-width: 420px){
    .footerContainer{
        flex-direction: row;
    }
}
@media (min-width: 900px){
    #aaa_socnetIcons1{
        justify-content: flex-end;
    }
}

#aaa_socnetIcons2{ width: 110px !important;  }

#aaa_disclaimer{
    position: relative;
    top: 15px; left: 0;
    width: 100%;
    height: auto; 
    font: 11px Arial, Helvetica;
    text-align: left;
    cursor: pointer;
    border-top: 1px solid #ccc;
    padding-top: 10px;
}
#aaa_disclaimer a{
    text-decoration: none;
    color: #38a4fc;
}
#aaa_disclaimer a:hover{
    color: #00f;
}

.aaa_hideDisclaimer{ opacity: 0.15;  }
/* .aaa_hideDisclaimer:hover{ opacity: 1; } */
.aaa_showDisclaimer{ opacity: 1; color: rgb(160, 160, 160);}


#aaa_share{  background: no-repeat url("../../img/socialnetworks/newsocnet1-v2.0.png") 5px 5px;}
#aaa_facebook{ background: no-repeat url("../../img/socialnetworks/newsocnet1-v2.0.png") -43px 3px;}
#aaa_linkedin{ background: no-repeat url("../../img/socialnetworks/newsocnet1-v2.0.png") -92px 3px;}
#aaa_youtube{  background: no-repeat url("../../img/socialnetworks/newsocnet2-v2.0.png") 5px 3px; }
#aaa_instagram{ background: no-repeat url("../../img/socialnetworks/newsocnet2-v2.0.png") -44px 5px; }

#aaa_share:hover,
#aaa_facebook:hover,
#aaa_linkedin:hover,
#aaa_youtube:hover,
#aaa_instagram:hover{
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.562), 0 0 10px rgba(43, 86, 121, 0.445) !important;
}

/* +++++++++++++ SOC NETS +++++++++++++ */


@media only screen {
    #aaaModule_Shares {
        position: fixed;
        max-width: 400px;
        width: 99%;
        /* height: 230px; */
        height: auto;
        padding: 7px;
        text-align: center;
        z-index: 10000;
    }
    .aaaModule_ShareFirstDiv {
        background-image: url(../../img/misc/bg-share.jpg);
    }
    .aaa_shares {
        height: 100% !important;
        padding: 10px;
        box-shadow: 0 7px 10px 1px #242424;
        border-radius: 2px;
        border: 1px solid #575757;
    }
    .sharethis {
        color: white;
        font: 26px/32px oswaldlight, Times;
        text-shadow: 0 4px 4px #000;
    }
    #aaaModule_SocialNets2{ display: none; }

    #pinit_pic {
        display: inline-block;
        width: 40px;
        height: 22px;
        background: no-repeat url(../../img/misc/aaa_sprite1.png) -2px -141px;
    }
}


/* ++++++++++++++++++ */
.swiper-footer{
    position: relative;
    /* width: 100%; */
    padding: 0;
    margin: 0 0 20px 0;
    font: 13px/20px Arial, Helvetica, Sans-serif;
    color: #333;
    text-align: center;
    flex-grow: 1;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center; */
}

@media (min-width: 350px){
    .swiper-footer{ text-align: left; }    
}

.sendemail{
    font-style: italic;
    color: #0cf; 
    cursor: pointer;
}
.sendemail:hover{ text-decoration: underline;}