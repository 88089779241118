/*Content*/
@media only screen{

    #aaa_newsletterContainer{
        position: relative;
        top: 0; 
        left: 0; width: 100%; 
        height: 0; 
        padding: 0 5px; 
        margin:  0;
        overflow: hidden;
        /* border: 1px solid red; */

        /* Valid ONLY for FIREFOX... other browsers will use -webkit settings */
        scrollbar-width: none;
    }


    .aaa_Post{
        position: relative;
        top: 0; left: 0; width: 100%; height: auto;
    }
    .aaa_Post td{ width: 100%; padding: 0; vertical-align: top; }
    #aaa_fp2.aaa_Post td{ width: 20%; padding: 0; vertical-align: top; }
    #cols3 {display: none; }
    .recentPostContainer{
        position: relative; top: 0; margin: auto; width: 96%; height: auto; text-align:center;
    }

    #aaa_journalColCategories{ position: relative; width: 100%; height: 100%}
    #aaa_journalColCategories td{
        height: 40px;
        vertical-align: middle;
        text-align: center;
        font-family:  Arial, Helvetica, sans-serif; 
        font-size: calc(7px + 3*((100vw - 300px)/870));
    }
    .c18, .c18M{ width: 18% !important; }
    .c2_5{ width: 2.5% !important; }        


    #aaa_row0{ height: 40px; text-align: center;}
    #aaa_row0 div{ 
        position: relative; float: left;
        height: 100%; 
        font-family:  Georgia, Times; 
        font-size: calc(10px + 2*((100vw - 300px)/870));
        line-height: 48px;
    }
    .aaa_row0Margins{ margin: 0 2.5% 0 0; padding: 0 !important;  }

    .colContainer{
		width: 100%; margin:0; top: 0;	    
    }
    .aaa_col{
        position: relative; width: 100%; 
		height: auto; 
		left: 0;
        padding: 7px; 
        border: 1px solid #d9d9d9;
    }  
    
    .aaa_colX{
        position: relative;
        width: 100%; height: auto;
        padding: 5px 15px 15px 15px;
    }
    .aaa_groupedBulletins{
        position: relative; top:0; left: 0; width: 100%; height: auto;
    }
    #aaa_archiveTitle{
        position: relative; top:10px; left: 0; width: 100%; height: auto; 
        margin: 10px 0;
        /* font: 20px 'Limelight'; */
        font: 20px Arial, Helvetica, sans-serif;
        text-align: center;
    }
    .tit1, .tit2, .tit3, .tit4, .tit5{
        position: relative; top: 0; left: 0;
        text-align: center; padding: 5px 0;
        font-weight: bold;
    }
    .colImg{
        position: relative;
        top: 0; left: 0; width: 100%; height: 250px;
        background: #ddd center no-repeat url('../../img/misc/miscelaneous3.png');
        margin-bottom: 5px;
    }
    .colImg:hover { cursor: pointer; }
    .colText{
        position: relative;
        top: 0; left: 0; width: 100%; height: auto;
        color: #898989; 
        overflow: hidden;
        text-align: justify;
        font: 11px Verdana, Arial, monospace;
    }
  	.colLink{position: relative; top: 0; left: 0; width: 100%; height: auto;
        margin-top: 7px;
        font: 12px Times, Georgia;
        color: #338ce0;
    }
    	.colLink:hover{cursor: pointer; }
    .colDate{
        position: relative; top: 0; left: 0; width: 100%; height: 20px;
        margin: 5px 0;
        font: 12px Times, Georgia;
        color: #888;
    }
    
    /* #aaa_fp3{ position: relative; margin: auto; width: 90%; } */
    #aaa_fp3{ position: relative; margin: auto; width: 100%; }
    .aaa_postTitle{
        position: relative; top: 0; left: 0; width: 100%; height: auto; 
        text-align: center; margin: 20px auto;
        /* font: 17px Georgia, Times; */
        font: 17px Helvetica;
    }
         

    .aaa_themetextTitle{ position: relative; top: 0; left: 0; width: auto; 
                        height: auto; margin: 7px 0; color: #636363;
                        font: bold 12px Helvetica, Verdana, Arial; }
    .aaa_themetextTitle2{ position: relative; top: 0; left: 0; width: auto; 
                        height: auto; margin: 7px 0; 
                        font: 12px Helvetica, Verdana, Arial; }
    
    div[id^="aaa_theme"]{ width: 100%; }
    div[id^="aaa_themetext"]{color: black;}
    div[id^="themetext"]:hover{ cursor: pointer;}
    
    #separator{
        position: relative;  left: 0; width: 100%; height: 25px; 
    }    

    #aaa_theme1{background-position: center 100%; }
    div[id^="themetext"]{ position: relative; margin: 5px 0; color: blue; } 

    figcaption[id^="aaa_NL5"],figcaption[id^="aaa_NL4"],
    figcaption[id^="aaa_NL3"],figcaption[id^="aaa_NL2"],
    figcaption[id^="aaa_NL1"]{
        font-size: calc(10px + 2*((100vw - 300px)/870));
    }

    /* ++++++++ 23/Oct/2018 NEW ++++++++++++++++ */
    .aaa_row0Col{ position: relative;  width: 20% !important; float: left; }
  
    .aaa_journalColL{ 
        margin: 0 0.9% 20px 0.9% !important; 
    }  
    
    .aaa_journalRowCol{
        position: relative; width: 100%; height: auto; 
    }
    .aaa_journalRowColIndiv, .aaa_journalRowColIndivMiddle{
        position: relative; 
        height: auto;
    }
    .aaa_journalThemeContainer{ padding: 7px; border: 1px solid #efefef; }
    .aaa_journalThemeContainer figure{ padding: 0; margin: 0; }



    .imgResponsive{ width: 100%; height: auto; }
    
    @media (orientation: portrait) {
        .aaa_journalRowColIndiv, .aaa_journalRowColIndivMiddle{ width: 100%; }
        .aaa_journalRowColIndivMiddle{ margin: 0 auto 20px auto; } 
    
        @media (min-width: 426px){
            .aaa_journalRowColIndiv{ width: 47.75%; float: left; } 
            .aaa_journalRowColIndivMiddle{ width: 60%; clear: both;}   
            /* div[id*="_3"]{ width: 100% !important; } */
        }
        @media (min-width: 768px){
            .aaa_journalRowColIndiv, .aaa_journalRowColIndivMiddle{ width: 18% !important; float: left  !important;}  
            .aaa_journalRowColIndivMiddle{ clear: none; }
        }
    }
    @media (orientation: landscape) {
        .aaa_journalRowColIndiv, .aaa_journalRowColIndivMiddle{ width: 18% !important; float: left !important;}
        .aaa_journalRowColIndivMiddle{ margin: 0 2.25% 20px 0 !important; }
    }
    
    

}     

 

/* ******************************** Responsive Design **************************** */
@media only screen{
    @media ( orientation: portrait ){
        /*Tablets*/
        @media ( max-width: 780px ){
		    table#aaa_fp3 { width: 100%; }
		    .modifyWidth{ padding: 5px !important; } 
            .aaa_col{ height: auto; }
            #projEmpty{ display: none; }
        }
        @media (min-width: 426px) AND ( max-width: 767px ){
            div[id*="_3"]{ width: 65% !important; clear: both; left: 17.5% !important; }
        }
        /*Smartphones*/
        @media ( max-width: 480px ){
            .colContainer{ width: 90% !important; margin: auto; }
            #aaa_archiveTitle{ font-size: 16px; }
            #aaa_row0{margin-top: 5px;}
        }
        @media ( max-width: 425px ){
            div[id*="_3"]{ width: 100%; left: 0 !important; }
        }
    }
    @media ( orientation: landscape ){
        /*Tablets*/
        @media ( max-width: 1050px ){
            .colContainer { 
            	margin: 0.5%; 
				width: 100%; 
            }
            .colImg{ height: 150px; }
            .aaa_col{ padding: 8px !important; } 
	    
        }
        /*Smartphones*/
        @media ( max-width: 829px ){
            #aaa_CentralContainer{ width: 100% !important; left: 0; }
            .colContainer{ width: 80%; margin: auto; }
            #aaa_archiveTitle{ font-size: 16px; }
            .colImg{ height: 200px; }
		    #projEmpty{ display: none; }
        }             
    }
    @media ( max-width: 1050px ){
        #aaa_CentralContainer{ width: 100% !important; } 
        #hd2{ font-size: 28px; }
        #hd3{ font-size: 14px; }
    }
    @media ( max-width: 480px ){
        #aaa_CentralContainer{ width: 100%; padding: 10px 5px; }
        #hd3{ font-size: 12px; }
    } 
    
} /* *************************************************************** */   
    




@media only screen {
  #aaaJournal {
    position: relative !important; 
    top: 0 !important; 
    left: 0 !important; 
    width: 100%;  
    margin: 0 !important; 
    padding: 0 !important;
    overflow: hidden;
    /* border: 1px solid red; */
  }


  #_aaaContainer_Journal {
    overflow: hidden;
    /* background: white no-repeat center url("../../img/misc/aaa_waitClock.gif"); */
    background: white;
  }

  #_aaaContainerScroller_Journal {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  
  @media (orientation: landscape) { 	
		@media (max-width: 750px){
		  #_aaaContainer_Journal {
		    width: 96% !important;
		    left: 2% !important;
		  }
		}

  }
}






/* JOURNAL */
@media only screen {
    #aaa_journalContainer{
        position: relative; 
        top: 0; left: 0; width: 100%; 
        /* height: 1650px; */
        height: auto;
        /* clear: both; */
        background: white;
    }

    #aaa_buttonSubscribe{
        position: relative;
        top: 0; width: 200px; 
        /* height: 30px;  */
        margin: 10px auto;
        padding: 10px 0; 
        border: 1px solid rgb(174, 174, 174);
        border-radius: 5px;
        background: rgb(178, 179, 179);
        box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.479);
        text-align: center;
        font: 14px/30px Arial, Helvetica, sans-serif;
        color:rgb(69, 90, 116);
        cursor: pointer;
    }
        #aaa_buttonSubscribe:hover{box-shadow: none; color: white; }
    
        #aaa_subscribeNL{
        position: fixed;
        top: 80px; width: 100%; height: calc(100% - 80px); margin: 0; padding: 0;
        background: rgba(255,255,255,0.85);
    }
    #aaa_subscribeClose{
        position: absolute;
        top: 5px; right: 5px; width: 48px; height: 48px;
        background: no-repeat center url('../../img/misc/close.png');
        cursor: pointer;
    }

    /* +++++++++++++++++++++++++++++++ New Journal  ++++++++++++++++++++++++++++++++ */
    /* #_aaaContainerScroller_Journal div{ border: 1px solid red; } */
    .aaa_journalCol{ padding: 0 !important; height: 230px; }

    .aaa_journalItemContainer{
        position: relative; top: 0; left: 0; width: 180px; padding: 0; margin: 0 auto;
        height: 230px;  
    }
    .aaa_journalItem{
        position: relative; top: 0; left: 0; width: 100%; padding: 7px; margin: 0 auto;
        height: 212px;
        border: 1px solid #ccc;
    }
    .aaa_journalItem2{
        position: relative; top: 0; left: 0; width: 100%; padding: 7px; margin: 0 auto;
        height: 220px;
    }
        @media (min-width: 768px) and (max-width: 991px){
            .aaa_journalItemContainer{ width: 100%; }
        }

 
    .aaa_journalItemPic{
        position: relative; top: 0; left: 0; width: 100%; padding: 0; margin: 0 auto;
        /* background: red; */
        height: 155px;
        cursor: pointer;
    }
    .aaa_journalItemText{
        position: relative; top: 0; left: 0; width: 100%; padding: 3px 0 0 0; margin: 0 auto;
        /* background: blue; */
        height: 43px;
        font: 12px Arial, Verdana, Helvetica;
    }
    .aaa_journalItemShadow{
        position: relative; top: 0; left: 0; width: 100%; padding: 0; margin: 0 auto;
        /* background: white; */
        height: 10px;
    }

    .aaa_journalDate{ font: 14px Georgia, Times; color: #aaa; }
    .aaa_message{ font: italic 12px Verdana, Helvetica, Arial; text-align: center; color: black; }

    .journalColumnsCurrentTable{ width: 18%; }
    .journalColumnsMargin { margin: 0 1% 0 1%}
    .journalColumns{ width: 20%; }



    /* NEW 23/Oct/2018 */
    #aaa_journalScroller{ position: relative; width: 100%; }


}


.tenue{ color: #999 !important; font-size: 13px; }
.font24{ font-size: 24px }
.black{color: black !important;}

#aaa_journalContainer{
    position: relative;
    margin-top: 100px
}


#aaa_CCHeader {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    text-align: center;
    overflow: hidden;
    padding: 15px;
    background-image: url(../../img/misc/zeitgeist.jpg);
}

.journalLegend{
    font-size: 0.8em;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 16px;
}

.aaa_shadow {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    margin: 0 auto;
    padding: 0;
    background-image: url(../../img/journal/shadow.png);
    background-repeat: no-repeat;
    background-position: center;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}


#journalBackButton{
    position: relative; 
    top: 0;
    width: 38px;
    height: 38px;
    background: center no-repeat url(../../img/misc/aaa_arrowLeft.jpg);
    cursor: pointer;
    z-index: 10
}