/* Individual Projects Container */
@media only screen {
    #aaa_projectsContainer{
        position: relative;
        top: 0; margin: 0; 
        margin-top: 100px;
        width: 100%;
        /* clear:both;
        overflow: hidden; */
    }

    #aaa_auxImgContainer{
        position: relative;
        top: 0; padding: 0; margin: 0;
        width: 100%; 
        background: no-repeat center url("../../img/misc/loader1.gif");
        background-size: 28px 28px; 
        overflow: hidden;
    }

    #aaa_projImgContainer{ 
        position: relative;
        top: 0; padding: 0; margin: 0;
        width: 100%; 
        /* background: no-repeat center url("../../img/misc/loader1.gif");
        background-size: 28px 28px; */
        /* height: 774px;  */
    }
    #aaa_projImgContainer img{ 
        position: relative;
        top: 0; padding: 0; margin: 0;
        /* width: 100%; 
        height: auto;  */
    }

    .aaa_projLocalization, .description3subtitle{ position: relative; font: 14px Georgia, Times; margin-bottom: 150px; }


    #aaa_projDescription, #aaa_projShare{
        position: relative; top: 0; margin: 0; padding: 0 10px; overflow: hidden; left: 0%; width: 100%; 
        text-align: justify;
    }
    
    #aaa_projTitle { 
        position: relative; top: 0; 
        margin: 0 0 0 auto; float: right;
        padding: 0 7px 0 0; overflow: hidden; 
        width: auto !important;
        height: 50px; 
        font-family: 'UbuntuLight', Verdana, Arial, Helvetica; 
        /* font-size: calc([minSize px] + ([maxSize] - [minSize]) * ((100vw - [minVW px]) / ([maxVW] - [minVW]))); */
        font-size: calc(16px + 3*((100vw - 300px)/870));
        /* line-height: calc(28px + 12*((100vw - 300px)/870)); */
        line-height: 50px;
    }
    #aaa_projShare { height: 50px; background: no-repeat center url('../../img/misc/shareit.png');}
    #aaa_projImg1, #aaa_projImg2{ display: none; }       
    .aaa_projImages{ width: 100%; height: auto; }
    #aaa_projDescription{ font: 14px/26px 'UbuntuLight', Helvetica, Verdana, Arial; color:#333}

    #aaa_projNext, #aaa_projPrev{
        display: none;
        position: absolute;
        top: 0;
        width: 30px;
        height: 100%;
        cursor: pointer;
    }
    #aaa_projNext{right: 0; background: center no-repeat url('../../img/misc/aaa_arrowR.png'); }
    #aaa_projPrev{left: 0; background: center no-repeat url('../../img/misc/aaa_arrowL.png');}


    @media (min-width: 585px){
        #aaa_projImg1, #aaa_projImg2, #aaa_projNext, #aaa_projPrev{ display: block; }
    }
    

    .backarrow {
        position: relative;
        float: left;
        top: 9px;
        left: 5px;
        width: 32px;
        height: 32px;
        background-image: url(../../img/misc/aaa_arrowLeft.jpg);
        cursor: pointer;
    }


    .aaaVideo {
        position: relative;
        top: 0;
        margin-left: calc(100% - 90px);
        width: 80px;
        height: 26px;
        background: no-repeat url("../../img/misc/aaaVideo.png") center;
        cursor: pointer;
    }

    #videoOverlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 20000;
        background: rgba(0, 0, 0, 0.9);
    }
    .aaa_youtubeVideo {
        position: absolute;
        top: 20%;
        left: 22%;
        width: 56%;
        height: 60%;
        box-shadow: 0 0 4px 1px #555;
    }

    @media only screen and (max-width: 1100px) and (orientation: landscape) {
        .aaa_youtubeVideo {
            top: 20%;
            left: 12%;
            width: 76%;
            height: 60%;
        }
    }

    @media only screen and (max-width: 810px) and (orientation: portrait) {
        .aaa_youtubeVideo {
            top: 20%;
            left: 0;
            width: 100%;
            height: 60%;
        }
    }

    #aaa_loader{
        position: absolute; top: calc(50% - 10px) !important; left: calc(50% - 10px)  !important; width: 20px !important; height: 20px !important;
    }
    #aaa_projLenght{
        position: absolute; top: 0; right: 0; width: 34px; height: 26px; background:rgba(0,0,0,0.6); color: white; font: 10px Arial, Helvetica; text-align: center; padding: 8px 3px
    }
      
}



.pifia1 {
    position: absolute;
    /* width: 100vw;
    height: 100vh; */
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    will-change: transform;
}

.pifia2 {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
    will-change: transform;
    box-shadow: 0 62.5px 125px -25px rgba(50, 50, 73, 0.5), 0 37.5px 75px -37.5px rgba(0, 0, 0, 0.6);
}


#aaa_projGalleryContainer{
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    z-index: 5;
}

#aaa_dummyImg0{
    z-index: 0;
}

:root{
    --animtime: 80s;
}

.projectimages{
  position: absolute !important;
  width: 100% !important;
  background-repeat: no-repeat;
  background-position: center;
  opacity:  0;
  /* animation:  projectAnim var(--animtime) cubic-bezier(.68,.88,.34,1.18) infinite;
  -webkit-animation:  projectAnim var(--animtime) cubic-bezier(.68,.88,.34,1.18) infinite; */
  animation-name: projectAnim;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.68,.88,.34,1.18);

}



/* @keyframes projectAnim { 0%  { opacity: 0  } 1%  { opacity: 1  } 5% { opacity: 1  } 6% { opacity: 0  } 100% { opacity: 0 } } */