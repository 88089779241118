@media only screen {
	#aaaModule_SubscribeScrollContainer{
	    position: absolute;
	    top: 10px;
	    width: 90%;
	    height: 100%;
	    left: 5%;
        padding: 0;
	}
  #_aaaContainer_StudioSubscribe {
    position: relative;
    /* top: 30px; */
    top: 2px;
    width: 96%;
    max-width: 850px;
    height: auto;
    margin: auto;
    /* left: 5%; */
    padding: 0;
    background: rgba(255, 255, 255, 0.3);
    border: 10px solid white;
    box-shadow: 0 2px 5px 1px rgba(0,0,0,0.5);
  }
  #subscribe1 {
    position: relative;
    top: 6.5%;
    width: 96%;
    margin: auto !important;
    height: auto;
    overflow: hidden;
    padding: 15px 3px;
    font: 13px Arial,Verdana,sans-serif !important;
    color: black;
    border: 1px solid white;
  }
  #citation5 {
    display: none;
    position: relative;
    top: 3%;
    float: right;
    width: 100%;
    height: auto;
    font: 12px/16px italic Ubuntu, Arial, Helvetica;
    color: #247424;
    clear: both;
  }

  #citation5 span {
    position: relative;
    float: right;
    font: 10px normal Georgia, 'Times New Roman';
    top: 2px;
    color: #ff5100;
  }

  #subscribe2 {    
    position: relative;
    top: 10px;
    margin-bottom: 15px;

  }

  #aaa_subsInput1{ width: 100% !important; }

  #extraspace {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
  }

  #extraspace2 {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 20px;
  }

  .subscribe1title {
    position: relative;
    top: 10px;
    width: 100%;
    height: auto;
    border-bottom: 1px solid black;
    font: 16px UbuntuLight, Verdana, Arial;
    color: #000;
    text-shadow: -1px 0 3px #555;
    clear: both;
  }

  #subscribe1 span {
    position: relative;
    text-align: left;
  }

  #divSprite1 {
    position: relative; top: 0; left: 0;
    float: left; 
    width: 28px;
    height: 28px;
    overflow: hidden;
    margin: 0 10px 5px 0;
  }

  .subscribeimg {
    position: absolute;
    width: 104px;
    height: 162px;
    clip: rect(74px, 67px, 102px, 39px);
    top: -74px;
    left: -40px;
  }

  #bgSubscribe{
    display: none;
    position: relative; top: 0; width: 100%; height: 270px; background: no-repeat center url('../../img/misc/bg-subscribe.jpg');
  }

	/* @media (orientation: landscape){ */
		@media (min-width: 600px){
      #citation5 { display: block; width: 65%; }
      #bgSubscribe{ display: block; }
		} 
		@media (min-width: 700px){
      #_aaaContainer_StudioSubscribe { border: 40px solid white; }
		} 
	/* } */


  #mc_embed_signup_scroll{ height: auto; }
  #mc_embed_signup_scroll table{ position: relative;  top: 0;  width: 98%; height: auto; border-collapse: collapse; padding:0; margin: 0; }
  .aaa_txtCenter{ text-align: center; }
  .aaa_hideInput{ position: absolute; left: -5000px; }
  .aaa_hideError{ display:none }
  .aaa_floatNone{float: none !important; }

}

